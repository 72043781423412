var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { watch, defineComponent, ref } from 'vue';
import classNames from '../_util/classNames';
import UpOutlined from '@ant-design/icons-vue/UpOutlined';
import DownOutlined from '@ant-design/icons-vue/DownOutlined';
import VcInputNumber, { inputNumberProps as baseInputNumberProps } from './src/InputNumber';
import { useInjectFormItemContext } from '../form/FormItemContext';
import useConfigInject from '../_util/hooks/useConfigInject';
import { cloneElement } from '../_util/vnode';
import omit from '../_util/omit';
import PropTypes from '../_util/vue-types';
import isValidValue from '../_util/isValidValue';
const baseProps = baseInputNumberProps();
export const inputNumberProps = () => (Object.assign(Object.assign({}, baseProps), { size: { type: String }, bordered: { type: Boolean, default: true }, placeholder: String, name: String, id: String, type: String, addonBefore: PropTypes.any, addonAfter: PropTypes.any, prefix: PropTypes.any, 'onUpdate:value': baseProps.onChange, valueModifiers: Object }));
const InputNumber = defineComponent({
    name: 'AInputNumber',
    inheritAttrs: false,
    props: inputNumberProps(),
    // emits: ['focus', 'blur', 'change', 'input', 'update:value'],
    slots: ['addonBefore', 'addonAfter', 'prefix'],
    setup(props, { emit, expose, attrs, slots }) {
        const formItemContext = useInjectFormItemContext();
        const { prefixCls, size, direction } = useConfigInject('input-number', props);
        const mergedValue = ref(props.value === undefined ? props.defaultValue : props.value);
        const focused = ref(false);
        watch(() => props.value, () => {
            mergedValue.value = props.value;
        });
        const inputNumberRef = ref(null);
        const focus = () => {
            var _a;
            (_a = inputNumberRef.value) === null || _a === void 0 ? void 0 : _a.focus();
        };
        const blur = () => {
            var _a;
            (_a = inputNumberRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        expose({
            focus,
            blur,
        });
        const handleChange = (val) => {
            if (props.value === undefined) {
                mergedValue.value = val;
            }
            emit('update:value', val);
            emit('change', val);
            formItemContext.onFieldChange();
        };
        const handleBlur = (e) => {
            focused.value = false;
            emit('blur', e);
            formItemContext.onFieldBlur();
        };
        const handleFocus = (e) => {
            focused.value = true;
            emit('focus', e);
        };
        return () => {
            var _a, _b, _c;
            const _d = Object.assign(Object.assign({}, attrs), props), { class: className, bordered, readonly, style, addonBefore = (_a = slots.addonBefore) === null || _a === void 0 ? void 0 : _a.call(slots), addonAfter = (_b = slots.addonAfter) === null || _b === void 0 ? void 0 : _b.call(slots), prefix = (_c = slots.prefix) === null || _c === void 0 ? void 0 : _c.call(slots), valueModifiers = {} } = _d, others = __rest(_d, ["class", "bordered", "readonly", "style", "addonBefore", "addonAfter", "prefix", "valueModifiers"]);
            const preCls = prefixCls.value;
            const mergeSize = size.value;
            const inputNumberClass = classNames({
                [`${preCls}-lg`]: mergeSize === 'large',
                [`${preCls}-sm`]: mergeSize === 'small',
                [`${preCls}-rtl`]: direction.value === 'rtl',
                [`${preCls}-readonly`]: readonly,
                [`${preCls}-borderless`]: !bordered,
            }, className);
            let element = (<VcInputNumber {...omit(others, ['size', 'defaultValue'])} ref={inputNumberRef} lazy={!!valueModifiers.lazy} value={mergedValue.value} class={inputNumberClass} prefixCls={preCls} readonly={readonly} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus} v-slots={{
                    upHandler: () => <UpOutlined class={`${preCls}-handler-up-inner`}/>,
                    downHandler: () => <DownOutlined class={`${preCls}-handler-down-inner`}/>,
                }}/>);
            const hasAddon = isValidValue(addonBefore) || isValidValue(addonAfter);
            if (isValidValue(prefix)) {
                const affixWrapperCls = classNames(`${preCls}-affix-wrapper`, {
                    [`${preCls}-affix-wrapper-focused`]: focused.value,
                    [`${preCls}-affix-wrapper-disabled`]: props.disabled,
                    [`${preCls}-affix-wrapper-rtl`]: direction.value === 'rtl',
                    [`${preCls}-affix-wrapper-readonly`]: readonly,
                    [`${preCls}-affix-wrapper-borderless`]: !bordered,
                    // className will go to addon wrapper
                    [`${className}`]: !hasAddon && className,
                });
                element = (<div class={affixWrapperCls} style={style} onMouseup={() => inputNumberRef.value.focus()}>
            <span class={`${preCls}-prefix`}>{prefix}</span>
            {element}
          </div>);
            }
            if (hasAddon) {
                const wrapperClassName = `${preCls}-group`;
                const addonClassName = `${wrapperClassName}-addon`;
                const addonBeforeNode = addonBefore ? (<div class={addonClassName}>{addonBefore}</div>) : null;
                const addonAfterNode = addonAfter ? <div class={addonClassName}>{addonAfter}</div> : null;
                const mergedWrapperClassName = classNames(`${preCls}-wrapper`, wrapperClassName, {
                    [`${wrapperClassName}-rtl`]: direction.value === 'rtl',
                });
                const mergedGroupClassName = classNames(`${preCls}-group-wrapper`, {
                    [`${preCls}-group-wrapper-sm`]: mergeSize === 'small',
                    [`${preCls}-group-wrapper-lg`]: mergeSize === 'large',
                    [`${preCls}-group-wrapper-rtl`]: direction.value === 'rtl',
                }, className);
                element = (<div class={mergedGroupClassName} style={style}>
            <div class={mergedWrapperClassName}>
              {addonBeforeNode}
              {element}
              {addonAfterNode}
            </div>
          </div>);
            }
            return cloneElement(element, { style });
        };
    },
});
export default Object.assign(InputNumber, {
    install: (app) => {
        app.component(InputNumber.name, InputNumber);
        return app;
    },
});
